<script>
import {
  ArrowUpIcon,
  MapPinIcon,
  UserCheckIcon,
  MonitorIcon,
  BookIcon,
  ClockIcon,
  DollarSignIcon,
  BriefcaseIcon,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
} from "vue-feather-icons";

import Navbar from "@/view/front/components/navbar";
import Switcher from "@/view/front/components/switcher";
import Footer from "@/view/front/components/footer";
import axios from "axios";
import { API_URL, MEDIA_URL } from "@/core/api";
import moment from "moment";
/**
 * Page-job-detail component
 */
export default {
  data() {
    return {
      search_text: "",
      selected_cities: [],
      auth: localStorage.getItem("id_token") !== null ? true : false,
      mediaUrl: MEDIA_URL,
      cities: this.$route.params.cities
        .split("-")
        .map((a) => a.charAt(0).toUpperCase() + a.substr(1)),
      title: this.makeTitle(this.$route.params.title),
      id: this.makeTitle(this.$route.params.id),
      data: null,
      applied: null,
      alreadysave: null,
      token: `Bearer ${localStorage.getItem("token")}`,
      saveJobSuccess: "",
      savejobbtn: "",
      snackbar: false,
      similarJobs: [],
      industry: null,
      skills: null,
      location_city: null,
    };
  },
  components: {
    Navbar,
    Switcher,
    Footer,
    ArrowUpIcon,
    MapPinIcon,
    UserCheckIcon,
    MonitorIcon,
    BookIcon,
    ClockIcon,
    DollarSignIcon,
    BriefcaseIcon,
    FacebookIcon,
    TwitterIcon,
    LinkedinIcon,
  },
  mounted() {
    console.log(this.id);
    localStorage.removeItem("route");
    axios
      .post(`${API_URL}/get-job`, {
        id: this.id,
        cities: this.cities,
        title: this.title,
      })
      .then((response) => {
        this.data = response.data;
        this.industry = response.data.industry;
        this.skills = response.data.require_skills;
        this.location_city = response.data.location_city;

        axios.defaults.headers.common["Authorization"] = this.token;

        axios
          .get(`${API_URL}/check-applied-job/${this.data.id}`)
          .then((res) => {
            if (res.data == true) {
              this.applied = true;
            } else {
              this.applied = false;
            }
            this.getSimilarJobs();
          });

        axios.defaults.headers.common["Authorization"] = this.token;
        axios
          .post(`${API_URL}/already-save-job`, {
            job_id: this.data.id,
          })
          .then((response) => {
            this.savejobbtn = response.data.msg;
          });
      });
  },
  methods: {
    makeTitle(slug) {
      var words = slug.split("-");
      for (var i = 0; i < words.length; i++) {
        var word = words[i];
        words[i] = word.charAt(0).toUpperCase() + word.slice(1);
      }
      return words.join(" ").replace("  ", " & ");
    },
    moment: function (date) {
      return moment(date);
    },
    search(item) {
      this.$router.push({
        path: "/jobs/Pakistan/" + item,
        params: {
          search_text: this.search_text,
          selected_cities: [{ id: 1, value: item }],
        },
      });
    },
    saveJob() {
      console.log("this.data");

      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .post(`${API_URL}/save-job`, {
          job_id: this.data.id,
        })
        .then((response) => {
          this.savejobbtn = response.data.msg;
          this.saveJobSuccess = response.data.msg;

          setTimeout(() => {
            this.saveJobSuccess = "";
          }, 3000);
        });
    },
    goToCity(item) {
      this.$router.replace({
        path: "/jobs/Pakistan/" + item,
        params: {
          search_text: this.search_text,
          selected_cities: [{ id: 1, value: item }],
        },
      });
    },
    getSimilarJobs() {
      axios
        .post(`${API_URL}/get-similar-jobs`, {
          industry: this.industry,
          skills: this.skills,
          city: this.location_city,
        })
        .then((r__es) => {
          this.similarJobs = r__es.data;
        });
    },
    _move() {
      localStorage.setItem("route", window.location.href);
    },
  },
};
</script>

<template>
  <div>
    <Navbar />

    <!-- srat ch -->

    <section
      class="section_ch_p d-table w-100 mb-20 mt-lg-15 section_ch_p_imb"
      v-if="data"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-8 padding_zero_imb">
            <div class="bg-white mr-1 p-0">
              <!-- /////////////////////////////////////// -->
              <div class="col-lg-12 col-md-7 col-12">
                <div class="col-lg-12 col-md-4 col-12 pl-0 pr-0">
                  <div
                    class="
                      card
                      overflow-hidden
                      ch-box
                      bg-white
                      border-0
                      pb-0
                      l_r_p_z
                    "
                  >
                    <div class="position position_style_ch row pb-0">
                      <router-link
                        v-if="data.location_city"
                        :to="
                          '/jobs-careers/pakistan/' +
                          data.location_city
                            .map((v) => v.toLowerCase())
                            .join('-') +
                          '/' +
                          data.job_title
                            .toLowerCase()
                            .replace(/ /g, '-')
                            .replace(/[^\w-]+/g, '') +
                          '/' +
                          data.id
                        "
                        class="text-dark col-lg-10 l_r_p_z"
                        >{{ data && data.job_title }}
                        <span class="job_function mobile_block_ch"
                          >({{ data.job_function }})</span
                        >
                      </router-link>
                      <div
                        class="col-lg-2 pr-0 Urgent_hiring text-center l_p_z"
                      >
                        <!-- <img
                          src="/images/job_page/Featured_new.svg"
                          alt="..."
                        /> -->
                        <p
                          class="text-white mb-0 detail_body px-2 full_time_btn"
                          v-for="(_item, _index) in data && data.job_type"
                          :key="_index"
                          style="background: #2eca8b"
                        >
                          <span
                            v-if="_index > 0 && _index < data.job_shift.length"
                            style=""
                            >,</span
                          >
                          {{ _item }}
                        </p>
                      </div>
                      <div class="co_name col-lg-10 l_r_p_z">
                        <router-link
                          style="font-size: 15px !important"
                          :to="
                            '/employer/' +
                            data.recruiter.company_name
                              .toLowerCase()
                              .replace(/ /g, '-')
                              .replace(/[^\w-]+/g, '')
                          "
                          class="company-name"
                        >
                          {{ data.recruiter.company_name }} </router-link
                        ><span>
                          <img src="/images/job_page/Verified.svg" alt="..." />
                        </span>
                      </div>
                      <div
                        class="
                          salary_style
                          pl-lg-3
                          pt-1
                          l_p_z
                          hide_on_mobile_ch
                        "
                      >
                        <img
                          class="pr-2"
                          src="/images/job_page/Money.svg"
                          alt="..."
                        />{{ data && data.salary_min }} -
                        {{ data && data.salary_max }}/Month
                      </div>

                      <div class="row pb-2 l_p_z hide_on_mobile_ch">
                        <div
                          class="
                            location_perant_div
                            col-lg-12
                            l_r_p_z
                            row
                            d-none
                          "
                        >
                          <span class="">
                            <img
                              class="pr-2 location_icon_ch"
                              src="/images/job_page/Location.svg"
                              alt="..."
                            />
                          </span>
                          <div
                            @click="goToCity(_data)"
                            class="
                              lo_name
                              locations_style
                              pl-1
                              pr-0
                              pt-2
                              col
                              d-flex
                            "
                            v-for="(_data, _index) in data.location_city"
                            :key="_index"
                            style="cursor: pointer"
                          >
                            <span
                              class="p-0 d-flex"
                              v-if="
                                _index > 0 && _index < data.location_city.length
                              "
                              style=""
                              >&nbsp;,&nbsp;</span
                            >
                            {{ _data }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="
                    job_details_btn job_details_btn_by_ch
                    border-bottom
                    mobile_block_ch
                    l_r_p_z
                  "
                >
                  <router-link
                    v-if="auth && !applied"
                    :to="
                      '/jobs-careers/apply/pakistan/' +
                      data.location_city.map((v) => v.toLowerCase()).join('-') +
                      '/' +
                      data.job_title
                        .toLowerCase()
                        .replace(/ /g, '-')
                        .replace(/[^\w-]+/g, '') +'/' + data.id
                    "
                    class="ml-1 mt-1"
                    ><img src="/images/job_page/Apply.svg" alt="..."
                  /></router-link>
                  <router-link v-if="!auth" :to="'/login'" class="m-1"
                    ><img src="/images/job_page/Apply.png" alt="..."
                  /></router-link>
                  <!-- <router-link
                    v-if="auth && applied"
                    :to="
                      '/jobs-careers/apply/pakistan/' +
                      data.location_city.map((v) => v.toLowerCase()).join('-') +
                      '/' +
                      data.job_title
                        .toLowerCase()
                        .replace(/ /g, '-')
                        .replace(/[^\w-]+/g, '')
                    "
                    :disabled="applied"
                    :event="!applied ? 'click' : ''"
                    class="m-1"
                    ><img src="/images/job_page/Applied.svg" alt="..."
                  /></router-link> -->
                  <button
                    type="button"
                    v-if="auth && applied"
                    @click="saveJob(detail)"
                    class="m-1"
                  >
                    <img src="/images/job_page/Applied.png" alt="..." />
                  </button>
                  <button
                    type="button"
                    v-if="auth && savejobbtn.length === 0"
                    @click="saveJob"
                    class="m-1"
                  >
                    <img src="/images/job_page/Save.svg" alt="..." />
                  </button>
                  <router-link v-if="!auth" :to="'/login'" class="m-1"
                    ><img src="/images/job_page/Save.png" alt="..."
                  /></router-link>
                  <button
                    type="button"
                    v-if="auth && savejobbtn.length > 0"
                    class="m-1"
                  >
                    <img src="/images/job_page/Saved.svg" alt="..." />
                  </button>
                  <router-link v-if="!auth" :to="'/login'" class="m-1"
                    ><img src="/images/job_page/Invite_friends.png" alt="..."
                  /></router-link>
                  <button v-if="auth" type="button" class="m-1">
                    <img src="/images/job_page/Invite_Friends.svg" alt="..." />
                  </button>
                  <div class="icons">
                    <ul class="list-unstyled head mb-0">
                      <ul class="list-unstyled social-icon">
                        <!-- style="text-align: right !important" -->
                        <ShareNetwork
                          network="twitter"
                          url="https://news.vuejs.org/issues/180"
                          title="Say hi to Vite! A brand new, extremely fast development setup for Vue."
                          description="This week, I’d like to introduce you to 'Vite', which means 'Fast'. It’s a brand new development setup created by Evan You."
                          quote="The hot reload is so fast it\'s near instant. - Evan You"
                          hashtags="job4u_pk"
                          twitterUser="youyuxi"
                        >
                          <li
                            class="list-inline-item ml-1"
                            style="margin-right: 0%"
                          >
                            <a
                              href="javascript:void(0)"
                              class="rounded"
                              style="border: none"
                              title="Share on Twitter"
                            >
                              <twitter-icon
                                class="fea icon-xs fea-social"
                              ></twitter-icon>
                            </a>
                          </li>
                        </ShareNetwork>

                        <ShareNetwork
                          network="facebook"
                          url="https://news.vuejs.org/issues/180"
                          title="Say hi to Vite! A brand new, extremely fast development setup for Vue."
                          description="This week, I’d like to introduce you to 'Vite', which means 'Fast'. It’s a brand new development setup created by Evan You."
                          quote="The hot reload is so fast it\'s near instant. - Evan You"
                          hashtags="job4u_pk"
                        >
                          <li class="list-inline-item" style="margin-right: 0%">
                            <a
                              href="javascript:void(0)"
                              class="rounded"
                              style="border: none"
                              title="Share on Facebook"
                            >
                              <facebook-icon
                                class="fea icon-sm fea-social"
                              ></facebook-icon>
                            </a>
                          </li>
                        </ShareNetwork>
                        <ShareNetwork
                          network="linkedin"
                          url="https://news.vuejs.org/issues/180"
                          title="Say hi to Vite! A brand new, extremely fast development setup for Vue."
                          description="This week, I’d like to introduce you to 'Vite', which means 'Fast'. It’s a brand new development setup created by Evan You."
                          quote="The hot reload is so fast it\'s near instant. - Evan You"
                          hashtags="job4u_pk"
                        >
                          <li class="list-inline-item">
                            <a
                              href="javascript:void(0)"
                              class="rounded"
                              style="border: none"
                              title="Share on LinkedIn"
                            >
                              <linkedin-icon
                                class="fea icon-sm fea-social"
                              ></linkedin-icon>
                            </a>
                          </li>
                        </ShareNetwork>
                      </ul>
                    </ul>
                  </div>
                </div>
                <div
                  class="
                    border-bottom
                    pl-lg-4
                    pr-lg-4
                    pb-lg-2
                    l_r_p_z
                    hide_on_mobile_ch
                  "
                >
                  <div class="row" style="margin-top: 1%">
                    <div class="col col-md-6">
                      <div
                        class="media widget align-items-center"
                        style="margin-top: 5%"
                      >
                        <div class="media-body d-flex">
                          <h4
                            class="
                              widget-title
                              tooltipch
                              mb-0
                              bold
                              detail_heading
                            "
                          >
                            <img
                              class="pr-2"
                              src="/images/job_page/Time_shift.svg"
                              alt="..."
                            />
                            <span class="tooltiptextch">Job shifts</span>
                          </h4>
                          <p
                            class="text-primary mb-0 detail_body"
                            v-for="(_item, _index) in data && data.job_shift"
                            :key="_index"
                          >
                            <span
                              v-if="
                                _index > 0 && _index < data.job_shift.length
                              "
                              style=""
                              >,</span
                            >
                            {{ _item }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col col-md-6">
                      <div
                        class="media widget align-items-center"
                        style="margin-top: 5%"
                      >
                        <div class="media-body d-flex">
                          <h4
                            class="
                              widget-title
                              tooltipch
                              mb-0
                              bold
                              detail_heading
                            "
                          >
                            <img
                              class="pr-2"
                              src="/images/job_page/Time_shift.svg"
                              alt="..."
                            />
                            <span class="tooltiptextch">Employment type</span>
                          </h4>
                          <span
                            class="text-primary mb-0 detail_body"
                            v-for="(_item, _index) in data && data.job_type"
                            :key="_index"
                          >
                            {{ _item }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row" style="margin-top: 1%">
                    <!-- number of vaccancies -->
                    <div class="col col-md-6">
                      <div
                        class="media widget align-items-center"
                        style="margin-top: 5%"
                      >
                        <div class="media-body d-flex">
                          <h4
                            class="
                              widget-title
                              tooltipch
                              mb-0
                              bold
                              detail_heading
                            "
                          >
                            <img
                              class="pr-2"
                              src="/images/job_page/No._of_vacancies.svg"
                              alt="..."
                            />
                            <span class="tooltiptextch"
                              >Number of vacancies</span
                            >
                          </h4>
                          <span class="text-primary mb-0 detail_body">
                            {{ data.no_of_vacancies }} Vacancies
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="col col-md-6">
                      <div class="media widget align-items-center mt-3">
                        <div class="media-body d-flex">
                          <h4
                            class="
                              widget-title
                              mb-0
                              tooltipch
                              bold
                              detail_heading
                            "
                          >
                            <img
                              class="pr-2"
                              src="/images/job_page/Sector.svg"
                              alt="..."
                              width="25"
                            />
                            <span class="tooltiptextch">Job function</span>
                          </h4>
                          <p class="text-primary mb-0 detail_body">
                            {{ data && data.job_function }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row" style="margin-top: 1%">
                    <div class="col col-md-6">
                      <div
                        class="media widget align-items-center"
                        style="margin-top: 5%"
                      >
                        <div class="media-body d-flex">
                          <h4
                            class="
                              widget-title
                              tooltipch
                              mb-0
                              bold
                              detail_heading
                            "
                          >
                            <img
                              class="pr-2"
                              src="/images/job_page/Applicants.svg"
                              alt="..."
                              width="25"
                            />
                            <span class="tooltiptextch"
                              >Total applicants to date</span
                            >
                          </h4>
                          <p class="text-primary mb-0 detail_body">
                            <!-- {{data}} -->
                            <span v-if="data.no_of_vacancies < 50">
                              Less than 50
                            </span>
                            <span v-if="data.no_of_vacancies > 50">
                              More than 50
                            </span>
                            <!-- {{ detail.job_application_count }} -->
                          </p>
                        </div>
                      </div>
                    </div>
                    <!-- salary -->
                    <div class="col col-md-6">
                      <div class="media widget align-items-center mt-3">
                        <div class="media-body d-flex">
                          <h4
                            class="
                              widget-title
                              tooltipch
                              mb-0
                              bold
                              detail_heading
                            "
                          >
                            <img
                              class="pr-2"
                              src="/images/job_page/Money_left.svg"
                              alt="..."
                            />
                            <span class="tooltiptextch">Per month salary</span>
                          </h4>
                          <p class="text-primary mb-0 detail_body">
                            +{{ data && data.salary_min }} to
                            {{ data && data.salary_max }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row" style="margin-top: 1%">
                    <div class="col col-md-6">
                      <div class="media widget align-items-center mt-3">
                        <div class="media-body d-flex">
                          <h4
                            class="
                              widget-title
                              tooltipch
                              mb-0
                              bold
                              detail_heading
                            "
                          >
                            <img
                              class="pr-2"
                              src="/images/job_page/Education_required.svg"
                              alt="..."
                            />
                            <span class="tooltiptextch"
                              >Minimum qualification</span
                            >
                          </h4>
                          <p class="text-primary mb-0 detail_body">
                            {{ data && data.education }}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="col col-md-6">
                      <div class="media widget align-items-center mt-3">
                        <div class="media-body d-flex">
                          <h4
                            class="
                              widget-title
                              tooltipch
                              mb-0
                              bold
                              detail_heading
                            "
                          >
                            <img
                              class="pr-2"
                              src="/images/job_page/Gender_preference.svg"
                              alt="..."
                            />
                            <span class="tooltiptextch">Gender preference</span>
                          </h4>
                          <p
                            class="text-primary mb-0 detail_body"
                            v-if="data.gender"
                          >
                            {{ data && data.gender.join("/ ") }}
                          </p>
                          <p
                            class="text-primary mb-0 detail_body"
                            v-if="!data.gender"
                          >
                            No preference
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row" style="margin-top: 1%">
                    <div class="col col-md-6">
                      <div class="media widget align-items-center mt-3">
                        <div class="media-body d-flex">
                          <h4
                            class="
                              widget-title
                              tooltipch
                              mb-0
                              bold
                              detail_heading
                            "
                          >
                            <img
                              class="pr-2"
                              src="/images/job_page/Date_posted.svg"
                              alt="..."
                            />
                            <span class="tooltiptextch">Job post date</span>
                          </h4>
                          <p class="text-primary mb-0 mb-0 detail_body">
                            {{ moment(data.created_at).format("MMMM Do YYYY") }}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="col col-md-6">
                      <div class="media widget align-items-center mt-3">
                        <div class="media-body d-flex">
                          <h4
                            class="
                              widget-title
                              tooltipch
                              mb-0
                              bold
                              detail_heading
                            "
                          >
                            <img
                              class="pr-2"
                              src="/images/job_page/Due_date.svg"
                              alt="..."
                            />
                            <span class="tooltiptextch"
                              >Application deadline</span
                            >
                          </h4>
                          <p class="text-primary mb-0 mb-0 detail_body">
                            {{ moment(data.apply_by).format("MMMM Do YYYY") }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row" style="margin-top: 1%">
                    <div class="col col-md-12 b_p_imb">
                      <div class="media widget align-items-center mt-3">
                        <div
                          class="media-body location_ch"
                          style="display: -webkit-box"
                        >
                          <h4
                            class="
                              widget-title
                              tooltipch
                              mb-0
                              bold
                              detail_heading
                            "
                          >
                            <img
                              class="pr-2"
                              src="/images/job_page/Location_left.svg"
                              alt="..."
                            />
                            <span class="tooltiptextch">Job locations</span>
                          </h4>
                          <div class="row">
                            <p
                              class="col col-md-3 text-primary mb-0 detail_body"
                              style="display: contents"
                            >
                              <span
                                v-for="(_item, _index) in data &&
                                data.location_city"
                                :key="_index"
                                style="display: inline-flex"
                              >
                                <span
                                  v-if="
                                    _index > 0 &&
                                    _index < data.location_city.length
                                  "
                                  style=""
                                  >&nbsp;,&nbsp;</span
                                >
                                {{ _item }}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="
                    border-bottom
                    pl-lg-4
                    pr-lg-4
                    pb-lg-2
                    l_r_p_z
                    hide_on_dectop_ch
                  "
                >
                  <div class="row" style="margin-top: 1%">
                    <div class="col col-md-6">
                      <div
                        class="media widget align-items-center"
                        style="margin-top: 5%"
                      >
                        <div class="media-body row m-0">
                          <h4
                            class="
                              widget-title
                              tooltipch
                              mb-0
                              bold
                              detail_heading
                              col-2
                              p-0
                            "
                          >
                            <img
                              class="pr-2"
                              src="/images/job_page/Time_shift.svg"
                              alt="..."
                            />
                            <span class="tooltiptextch">Job shift</span>
                          </h4>
                          <div class="col-10 p-0">
                            <p
                              class="gray mb-0 detail_body col-12 p-0"
                              style="
                                color: black;
                                font-weight: 600;
                                text-transform: uppercase;
                              "
                            >
                              Job shift
                            </p>
                            <p
                              class="text-primary mb-0 detail_body col-12 p-0"
                              v-for="(_item, _index) in data && data.job_type"
                              :key="_index"
                            >
                              <span
                                v-if="
                                  _index > 0 && _index < data.job_shift.length
                                "
                                style=""
                                >,</span
                              >
                              {{ _item }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col col-md-6">
                      <div
                        class="media widget align-items-center"
                        style="margin-top: 5%"
                      >
                        <div class="media-body row m-0">
                          <h4
                            class="
                              widget-title
                              tooltipch
                              mb-0
                              bold
                              detail_heading
                              col-2
                              p-0
                            "
                          >
                            <img
                              class="pr-2"
                              src="/images/job_page/Time_shift.svg"
                              alt="..."
                            />
                            <span class="tooltiptextch">Employment type</span>
                          </h4>
                          <div class="col-10 p-0">
                            <p
                              class="gray mb-0 detail_body col-12 p-0"
                              style="
                                color: black;
                                font-weight: 600;
                                text-transform: uppercase;
                              "
                            >
                              Job type
                            </p>
                            <span
                              class="text-primary mb-0 detail_body col-12 p-0"
                              v-for="(_item, _index) in data && data.job_type"
                              :key="_index"
                            >
                              {{ _item }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row" style="margin-top: 1%">
                    <!-- number of vaccancies -->
                    <div class="col col-md-6">
                      <div
                        class="media widget align-items-center"
                        style="margin-top: 5%"
                      >
                        <div class="media-body row m-0">
                          <h4
                            class="
                              widget-title
                              tooltipch
                              mb-0
                              bold
                              detail_heading
                              col-2
                              p-0
                            "
                          >
                            <img
                              class="pr-2"
                              src="/images/job_page/No._of_vacancies.svg"
                              alt="..."
                            />
                            <span class="tooltiptextch"
                              >Number of vacancies</span
                            >
                          </h4>
                          <div class="col-10 p-0">
                            <p
                              class="gray mb-0 detail_body col-12 p-0"
                              style="
                                color: black;
                                font-weight: 600;
                                text-transform: uppercase;
                              "
                            >
                              No. of vacancies
                            </p>
                            <span
                              class="text-primary mb-0 detail_body col-12 p-0"
                            >
                              {{ data.no_of_vacancies }} Vacancies
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col col-md-6">
                      <div class="media widget align-items-center mt-3">
                        <div class="media-body row m-0">
                          <h4
                            class="
                              widget-title
                              mb-0
                              tooltipch
                              bold
                              detail_heading
                              col-2
                              p-0
                            "
                          >
                            <img
                              class="pr-2"
                              src="/images/job_page/Sector.svg"
                              alt="..."
                              width="25"
                            />
                            <span class="tooltiptextch">Job function</span>
                          </h4>
                          <div class="col-10 p-0">
                            <p
                              class="gray mb-0 detail_body col-12 p-0"
                              style="
                                color: black;
                                font-weight: 600;
                                text-transform: uppercase;
                              "
                            >
                              Job function
                            </p>
                            <p class="text-primary mb-0 detail_body col-12 p-0">
                              {{ data && data.job_function }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row" style="margin-top: 1%">
                    <div class="col col-md-6">
                      <div
                        class="media widget align-items-center"
                        style="margin-top: 5%"
                      >
                        <div class="media-body row m-0">
                          <h4
                            class="
                              widget-title
                              tooltipch
                              mb-0
                              bold
                              detail_heading
                              col-2
                              p-0
                            "
                          >
                            <img
                              class="pr-2"
                              src="/images/job_page/Applicants.svg"
                              alt="..."
                              width="25"
                            />
                            <span class="tooltiptextch"
                              >Total applicants to date</span
                            >
                          </h4>
                          <div class="col-10 p-0">
                            <p
                              class="gray mb-0 detail_body col-12 p-0"
                              style="
                                color: black;
                                font-weight: 600;
                                text-transform: uppercase;
                              "
                            >
                              Applicants
                            </p>
                            <p class="text-primary mb-0 detail_body col-12 p-0">
                              <!-- {{data}} -->
                              <span v-if="data.no_of_vacancies < 50">
                                Less than 50
                              </span>
                              <span v-if="data.no_of_vacancies > 50">
                                More than 50
                              </span>
                              <!-- {{ detail.job_application_count }} -->
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- salary -->
                    <div class="col col-md-6">
                      <div class="media widget align-items-center mt-3">
                        <div class="media-body row m-0">
                          <h4
                            class="
                              widget-title
                              tooltipch
                              mb-0
                              bold
                              detail_heading
                              col-2
                              p-0
                            "
                          >
                            <img
                              class="pr-2"
                              src="/images/job_page/Money_left.svg"
                              alt="..."
                              width="25"
                            />
                            <span class="tooltiptextch">Per month salary</span>
                          </h4>
                          <div class="col-10 p-0">
                            <p
                              class="gray mb-0 detail_body col-12 p-0"
                              style="
                                color: black;
                                font-weight: 600;
                                text-transform: uppercase;
                              "
                            >
                              Salary
                            </p>
                            <p class="text-primary mb-0 detail_body col-12 p-0">
                              +{{ data && data.salary_min }} to
                              {{ data && data.salary_max }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row" style="margin-top: 1%">
                    <div class="col col-md-6">
                      <div class="media widget align-items-center mt-3">
                        <div class="media-body row m-0">
                          <h4
                            class="
                              widget-title
                              tooltipch
                              mb-0
                              bold
                              detail_heading
                              col-2
                              p-0
                            "
                          >
                            <img
                              class="pr-2"
                              src="/images/job_page/Education_required.svg"
                              alt="..."
                            />
                            <span class="tooltiptextch"
                              >Min. Qualification</span
                            >
                          </h4>
                          <div class="col-10 p-0">
                            <p
                              class="gray mb-0 detail_body col-12 p-0"
                              style="
                                color: black;
                                font-weight: 600;
                                text-transform: uppercase;
                              "
                            >
                              Education
                            </p>
                            <p class="text-primary mb-0 detail_body col-12 p-0">
                              {{ data && data.education }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col col-md-6">
                      <div class="media widget align-items-center mt-3">
                        <div class="media-body row m-0">
                          <h4
                            class="
                              widget-title
                              tooltipch
                              mb-0
                              bold
                              detail_heading
                              col-2
                              p-0
                            "
                          >
                            <img
                              class="pr-2"
                              src="/images/job_page/Gender_preference.svg"
                              alt="..."
                            />
                            <span class="tooltiptextch">Gender preference</span>
                          </h4>
                          <div class="col-10 p-0">
                            <p
                              class="gray mb-0 detail_body col-12 p-0"
                              style="
                                color: black;
                                font-weight: 600;
                                text-transform: uppercase;
                              "
                            >
                              Gender
                            </p>
                            <p
                              class="text-primary mb-0 detail_body col-12 p-0"
                              v-if="data.gender"
                            >
                              {{ data && data.gender.join("/ ") }}
                            </p>
                            <p
                              class="text-primary mb-0 detail_body"
                              v-if="!data.gender"
                            >
                              No preference
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row" style="margin-top: 1%">
                    <div class="col col-md-6">
                      <div class="media widget align-items-center mt-3">
                        <div class="media-body row m-0">
                          <h4
                            class="
                              widget-title
                              tooltipch
                              mb-0
                              bold
                              detail_heading
                              col-2
                              p-0
                            "
                          >
                            <img
                              class="pr-2"
                              src="/images/job_page/Date_posted.svg"
                              alt="..."
                            />
                            <span class="tooltiptextch">Job post date</span>
                          </h4>
                          <div class="col-10 p-0">
                            <p
                              class="gray mb-0 detail_body col-12 p-0"
                              style="
                                color: black;
                                font-weight: 600;
                                text-transform: uppercase;
                              "
                            >
                              Date Posted
                            </p>
                            <p class="text-primary mb-0 detail_body col-12 p-0">
                              {{
                                moment(data.created_at).format("MMMM Do YYYY")
                              }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col col-md-6">
                      <div class="media widget align-items-center mt-3">
                        <div class="media-body row m-0">
                          <h4
                            class="
                              widget-title
                              tooltipch
                              mb-0
                              bold
                              detail_heading
                              col-2
                              p-0
                            "
                          >
                            <img
                              class="pr-2"
                              src="/images/job_page/Due_date.svg"
                              alt="..."
                            />
                            <span class="tooltiptextch"
                              >Application deadline</span
                            >
                          </h4>
                          <div class="col-10 p-0">
                            <p
                              class="gray mb-0 detail_body col-12 p-0"
                              style="
                                color: black;
                                font-weight: 600;
                                text-transform: uppercase;
                              "
                            >
                              Deadline
                            </p>
                            <p class="text-primary mb-0 detail_body col-12 p-0">
                              {{ moment(data.apply_by).format("MMMM Do YYYY") }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row" style="margin-top: 1%">
                    <div class="col col-md-12 b_p_imb">
                      <div class="media widget align-items-center mt-3">
                        <div
                          class="media-body location_ch"
                          style="display: -webkit-box"
                        >
                          <h4
                            class="
                              widget-title
                              tooltipch
                              mb-0
                              bold
                              detail_heading
                              col-2
                              p-0
                            "
                          >
                            <img
                              class="pr-2"
                              src="/images/job_page/Location_left.svg"
                              alt="..."
                            />
                            <span class="tooltiptextch">Job locations</span>
                          </h4>
                          <div class="col-10 p-0">
                            <p
                              class="gray mb-0 detail_body col-12 p-0"
                              style="
                                color: black;
                                font-weight: 600;
                                text-transform: uppercase;
                              "
                            >
                              Location
                            </p>
                            <div class="">
                              <p
                                class="
                                  col col-md-3
                                  text-primary
                                  mb-0
                                  detail_body
                                "
                                style="display: contents"
                              >
                                <span
                                  v-for="(_item, _index) in data &&
                                  data.location_city"
                                  :key="_index"
                                  style="display: inline-flex"
                                >
                                  <span
                                    v-if="
                                      _index > 0 &&
                                      _index < data.location_city.length
                                    "
                                    style=""
                                    >&nbsp;,&nbsp;</span
                                  >
                                  {{ _item }}
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /////////////////////////////////////// -->
              <div class="p-10">
                <h3 class="panel-title" v-if="data.benefits">
                  <b>Benefits</b>
                </h3>
                <span
                  v-for="(_item, _index) in data && data.benefits"
                  :key="_index"
                  style="
                    display: inline-flex;
                    background: #349fe6;
                    color: white;
                    font-weight: bold;
                    padding: 5px 15px;
                    text-align: center !important;
                    margin-right: 13px;
                    margin-top: 13px;
                  "
                >
                  <!-- <span
                    v-if="_index > 0 && _index < data.benefits.length"
                    style=""
                    >&nbsp;,&nbsp;</span
                  > -->
                  {{ _item }}
                </span>
                <h3 class="panel-title mt-5"><b>Job Description</b></h3>
                <!-- {{ data }} -->
                <p
                  class="text-muted list_style_imb"
                  style="overflow: hidden"
                  v-html="data && data.job_description"
                ></p>
                <!-- <h3 class="panel-title"><b>Nature of the Job</b></h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Aenean euismod bibendum laoreet. Proin gravida dolor sit amet
                  lacus accumsan et viverra justo commodo. Proin sodales
                  pulvinar tempor. Cum sociis natoque penatibus et magnis dis
                  parturient montes, nascetur ridiculus mus.
                </p> -->

                <!-- <h3 class="panel-title"><b>Required Skills</b></h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Aenean euismod bibendum laoreet. Proin gravida dolor sit amet
                  lacus accumsan et viverra justo commodo. Proin sodales
                  pulvinar tempor. Cum sociis natoque penatibus et magnis dis
                  parturient montes, nascetur ridiculus mus.
                </p> -->

                <!-- <h3 class="panel-title"><b>Other Requirements</b></h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Aenean euismod bibendum laoreet. Proin gravida dolor sit amet
                  lacus accumsan et viverra justo commodo.
                </p> -->

                <!-- <h3 class="panel-title"><b>How to Apply?</b></h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> -->
                <router-link
                  v-if="!auth"
                  :to="'/login'"
                  class="mt-7 logoutApply mt-1 d-block"
                >
                  Apply
                </router-link>
                <router-link
                  v-if="auth && !applied"
                  :to="
                    '/jobs-careers/apply/pakistan/' +
                    data.location_city.map((v) => v.toLowerCase()).join('-') +
                    '/' +
                    data.job_title
                      .toLowerCase()
                      .replace(/ /g, '-')
                      .replace(/[^\w-]+/g, '') +'/' + data.id
                  "
                  class="mt-10 logoutApply mt-1"
                >
                  Apply Now
                </router-link>
                <router-link
                  v-if="auth && applied"
                  :to="
                    '/jobs-careers/apply/pakistan/' +
                    data.location_city.map((v) => v.toLowerCase()).join('-') +
                    '/' +
                    data.job_title
                      .toLowerCase()
                      .replace(/ /g, '-')
                      .replace(/[^\w-]+/g, '')
                  "
                  :disabled="applied"
                  :event="!applied ? 'click' : ''"
                  class="mt-10 logoutApply logoutApply1 mt-1"
                  >Applied</router-link
                >
              </div>
              <!-- <hr /> -->
              <!-- <div class="media">
                <div class="media-left">
                  <a href="#">
                    <img
                      :src="data && mediaUrl + data.recruiter.profile_pic"
                      class="avatar avatar-small ch_img_border"
                      alt=""
                    />
                  </a>
                </div>
                <div class="media-body media-right pt-2">
                  <p class="text-muted">Posted by</p>
                  <h4 class="media-heading text-primary">
                    <a href="#" class="">
                      <router-link
                        class="compony_name_ch"
                        style="
                          font-size: 24px;
                          color: #337ab7;
                          text-transform: uppercase;
                          font-weight: 600;
                        "
                        :to="
                          '/employer/' +
                          data.recruiter.company_name
                            .toLowerCase()
                            .replace(/ /g, '-')
                            .replace(/[^\w-]+/g, '')
                        "
                      >
                        {{ data.recruiter.company_name }}
                      </router-link>
                    </a>
                  </h4>
                </div>
              </div> -->
            </div>
          </div>
          <!-- side bar -->
          <div
            class="col-xs-12 col-sm-4 col-md-4 col-lg-4 p-lg-0 t_m_imb l_r_p_z"
          >
            <div class="well blue-head p-0 bg-white">
              <div class="panel-heading bluebg p-3 rounded-0">
                <h2
                  class="align-center"
                  style="font-size: 29px; font-weight: 700"
                >
                  <img width="23" src="/images/job_in_pk/Arrow.png" alt="" />
                  Get the job, easier!
                </h2>
              </div>
              <p
                class="align-center pt-4 mb-0"
                style="font-size: 18px; color: green; font-weight: 500"
              >
                Join job4u.pk <span style="color: #a9250f">Now</span>
              </p>
              <div class="panel-body p-4">
                <p class="align-center" style="font-size: 12px">
                  Cant find the job you are looking for at the moment? Join
                  job4u.pk and open door of opportunities for yourself.
                </p>
                <div class="align-center">
                  <button class="btn submitBnt text-white">
                    <a href="/job-seeker-register"></a>
                    Submit Your CV
                  </button>
                </div>
              </div>
            </div>
            <div class="well space bg-white rounded-0 t_m_imb">
              <div class="panel-body">
                <h1 class="auth_title border-bottom align-center py-3">
                  Application Procedure
                </h1>
                <img
                  class="w-100"
                  src="/images/job_page/Application_procedure.png"
                  alt=""
                />
                <!-- <p class="align-center">
                  Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                  Aenean commodo ligula eget dolor. Aenean massa. Cum sociis
                  natoque penatibus et magnis dis parturient montes, nascetur
                  ridiculus mus.
                </p> -->
              </div>
            </div>
          </div>
          <!--end side bar -->
        </div>
      </div>
    </section>

    <section
      class="section_ch_p d-table w-100 mb-20 mt-5"
      v-if="!data"
      style="height: 100%"
    ></section>
    <!-- end ch -->
    <!-- Hero Start -->

    <!--end section-->
    <!-- Hero End -->

    <!-- Shape Start -->
    <div class="position-relative">
      <div class="shape overflow-hidden text-white">
        <svg
          viewBox="0 0 2880 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
            fill="currentColor"
          ></path>
        </svg>
      </div>
    </div>
    <!--Shape End-->

    <!-- Job Detail Start -->
    <section class="section d-none" v-if="data">
      <div class="container">
        <div class="row">
          <div class="col-lg-4">
            <div class="col-lg-12 col-md-5 col-12">
              <div class="card sidebar rounded border-0 rounded-0">
                <div class="card-body widget border-bottom">
                  <h5 class="mb-0">Job Information</h5>
                </div>

                <div class="card-body">
                  <div class="media widget align-items-center">
                    <user-check-icon
                      class="fea icon-ex-md mr-3"
                    ></user-check-icon>
                    <div class="media-body">
                      <h4 class="widget-title mb-0">Job Shift:</h4>
                      <p
                        class="text-primary mb-0"
                        v-for="(_item, _index) in data && data.job_shift"
                        :key="_index"
                      >
                        {{ _item }}
                      </p>
                    </div>
                  </div>

                  <div class="media widget align-items-center mt-3">
                    <user-check-icon
                      class="fea icon-ex-md mr-3"
                    ></user-check-icon>
                    <div class="media-body">
                      <h4 class="widget-title mb-0">Employment Type:</h4>
                      <p
                        class="text-primary mb-0"
                        v-for="(_item, _index) in data && data.job_type"
                        :key="_index"
                      >
                        {{ _item }}
                      </p>
                    </div>
                  </div>

                  <div class="media widget align-items-center mt-3">
                    <map-pin-icon class="fea icon-ex-md mr-3"></map-pin-icon>
                    <div class="media-body">
                      <h4 class="widget-title mb-0">Location:</h4>
                      <p
                        class="text-primary mb-0"
                        v-for="(_item, _index) in data && data.location_city"
                        :key="_index"
                      >
                        {{ _item }}
                      </p>
                    </div>
                  </div>

                  <div class="media widget align-items-center mt-3">
                    <monitor-icon class="fea icon-ex-md mr-3"></monitor-icon>
                    <div class="media-body">
                      <h4 class="widget-title mb-0">Job Sector:</h4>
                      <p class="text-primary mb-0">
                        {{ data && data.job_function }}
                      </p>
                    </div>
                  </div>

                  <div class="media widget align-items-center mt-3">
                    <briefcase-icon
                      class="fea icon-ex-md mr-3"
                    ></briefcase-icon>
                    <div class="media-body">
                      <h4 class="widget-title mb-0">Experience:</h4>
                      <p class="text-primary mb-0">
                        {{ data && data.minimum_experience_years + " years" }}
                      </p>
                    </div>
                  </div>

                  <div class="media widget align-items-center mt-3">
                    <briefcase-icon
                      class="fea icon-ex-md mr-3"
                    ></briefcase-icon>
                    <div class="media-body">
                      <h4 class="widget-title mb-0">Career Lavel:</h4>
                      <p class="text-primary mb-0">
                        {{ data && data.experience[0] }}
                      </p>
                    </div>
                  </div>

                  <div class="media widget align-items-center mt-3">
                    <book-icon class="fea icon-ex-md mr-3"></book-icon>
                    <div class="media-body">
                      <h4 class="widget-title mb-0">Qualifications:</h4>
                      <p class="text-primary mb-0">
                        {{ data && data.education }}
                      </p>
                    </div>
                  </div>

                  <div class="media widget align-items-center mt-3">
                    <dollar-sign-icon
                      class="fea icon-ex-md mr-3"
                    ></dollar-sign-icon>
                    <div class="media-body">
                      <h4 class="widget-title mb-0">Salary:</h4>
                      <p class="text-primary mb-0">
                        +{{ data && data.salary_min }} to
                        {{ data && data.salary_max }}
                      </p>
                    </div>
                  </div>

                  <div class="media widget align-items-center mt-3">
                    <clock-icon class="fea icon-ex-md mr-3"></clock-icon>
                    <div class="media-body">
                      <h4 class="widget-title mb-0">Date posted:</h4>
                      <p class="text-primary mb-0 mb-0">
                        {{ moment(data.created_at).format("MMMM Do YYYY") }}
                      </p>
                    </div>
                  </div>

                  <div class="media widget align-items-center mt-3">
                    <clock-icon class="fea icon-ex-md mr-3"></clock-icon>
                    <div class="media-body">
                      <h4 class="widget-title mb-0">Application deadline:</h4>
                      <p class="text-primary mb-0 mb-0">
                        {{ moment(data.apply_by).format("MMMM Do YYYY") }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-12 col-md-5 col-12" style="margin-top: 3%">
              <div class="card sidebar rounded border-0 rounded-0">
                <div class="card-body widget border-bottom">
                  <h5 class="mb-0">Similar Jobs</h5>
                </div>

                <div class="card-body">
                  <div class="media widget align-items-center">
                    <div class="media-body">
                      <ul style="padding-left: 6%">
                        <li
                          class="text-primary mb-0"
                          v-for="(_item, _index) in similarJobs"
                          :key="_index"
                        >
                          <router-link
                            :to="
                              '/jobs-careers/pakistan/' +
                              _item.location_city
                                .map((v) => v.toLowerCase())
                                .join('-') +
                              '/' +
                              _item.job_title
                                .toLowerCase()
                                .replace(/ /g, '-')
                                .replace(/[^\w-]+/g, '') +
                              '/' +
                              _item.id
                            "
                            target="_blank"
                            class="text-dark"
                            >{{ _item.job_title }}</router-link
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-8">
            <div class="col-lg-12 col-md-7 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
              <div class="ml-lg-4">
                <h5><b>Job Description:</b></h5>
                <p class="text-muted" v-html="data && data.job_description"></p>

                <p
                  class="text-muted"
                  v-html="data && data.application_procedure"
                ></p>

                <div
                  class="mt-4"
                  style="display: flex; justify-content: space-between"
                >
                  <div class="widget" style="width: 60%">
                    <h4>Application Procedure:</h4>
                    <router-link
                      v-if="auth && !applied"
                      :to="
                        '/jobs-careers/apply/pakistan/' +
                        data.location_city
                          .map((v) => v.toLowerCase())
                          .join('-') +
                        '/' +
                        data.job_title
                          .toLowerCase()
                          .replace(/ /g, '-')
                          .replace(/[^\w-]+/g, '')
                      "
                      class="btn btn-outline-primary"
                      >Apply Now <i class="mdi mdi-send"></i
                    ></router-link>
                    <!-- <button
                      v-if="auth"
                      @click="saveJob"
                      class="btn btn-outline-primary"
                      style=" margin-bottom: 5%"
                    >
                      Save job <i class="mdi mdi-save"></i>
                    </button> -->
                    <router-link
                      v-if="auth && applied"
                      :to="
                        '/jobs-careers/apply/pakistan/' +
                        data.location_city
                          .map((v) => v.toLowerCase())
                          .join('-') +
                        '/' +
                        data.job_title
                          .toLowerCase()
                          .replace(/ /g, '-')
                          .replace(/[^\w-]+/g, '')
                      "
                      :disabled="applied"
                      :event="!applied ? 'click' : ''"
                      class="btn btn-outline-primary"
                      >Already applied <i class="mdi mdi-send"></i
                    ></router-link>
                    <router-link
                      v-if="!auth"
                      to="/login"
                      @click.native="_move()"
                      class="btn btn-outline-primary"
                      >Apply Now <i class="mdi mdi-send"></i
                    ></router-link>

                    <div
                      class="alert alert-success"
                      role="alert"
                      style="margin-top: 10%"
                      v-if="saveJobSuccess.length > 0"
                    >
                      {{ saveJobSuccess }}
                    </div>
                  </div>

                  <div class="widget" style="width: 40%">
                    <h4 class="widget-title">Share</h4>
                    <div>
                      <ul class="list-unstyled social-icon mb-0 mt-4">
                        <ShareNetwork
                          network="twitter"
                          url="https://news.vuejs.org/issues/180"
                          title="Say hi to Vite! A brand new, extremely fast development setup for Vue."
                          description="This week, I’d like to introduce you to 'Vite', which means 'Fast'. It’s a brand new development setup created by Evan You."
                          quote="The hot reload is so fast it\'s near instant. - Evan You"
                          hashtags="job4u_pk"
                          twitterUser="youyuxi"
                        >
                          <li
                            class="list-inline-item ml-1"
                            style="margin-right: 4%"
                          >
                            <a href="javascript:void(0)" class="rounded">
                              <twitter-icon
                                class="fea icon-sm fea-social"
                              ></twitter-icon>
                            </a>
                          </li>
                        </ShareNetwork>

                        <ShareNetwork
                          network="facebook"
                          url="https://news.vuejs.org/issues/180"
                          title="Say hi to Vite! A brand new, extremely fast development setup for Vue."
                          description="This week, I’d like to introduce you to 'Vite', which means 'Fast'. It’s a brand new development setup created by Evan You."
                          quote="The hot reload is so fast it\'s near instant. - Evan You"
                          hashtags="job4u_pk"
                        >
                          <li class="list-inline-item" style="margin-right: 4%">
                            <a href="javascript:void(0)" class="rounded">
                              <facebook-icon
                                class="fea icon-sm fea-social"
                              ></facebook-icon>
                            </a>
                          </li>
                        </ShareNetwork>
                        <ShareNetwork
                          network="linkedin"
                          url="https://news.vuejs.org/issues/180"
                          title="Say hi to Vite! A brand new, extremely fast development setup for Vue."
                          description="This week, I’d like to introduce you to 'Vite', which means 'Fast'. It’s a brand new development setup created by Evan You."
                          quote="The hot reload is so fast it\'s near instant. - Evan You"
                          hashtags="job4u_pk"
                        >
                          <li
                            class="list-inline-item ml-1"
                            style="margin-right: 4%"
                          >
                            <a href="javascript:void(0)" class="rounded">
                              <linkedin-icon
                                class="fea icon-sm fea-social"
                              ></linkedin-icon>
                            </a>
                          </li>
                        </ShareNetwork>
                      </ul>
                    </div>

                    <!--end icon-->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end col-->

        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- Job Detail End -->
    <!--end section-->
    <Footer />
    <!-- Footer End -->
    <Switcher />
    <!-- Back to top -->
    <a
      href="javascript: void(0);"
      class="btn btn-icon btn-soft-primary back-to-top"
      id="back-to-top"
      v-scroll-to="'#topnav'"
    >
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
  </div>
</template>
