<script>
import {
  FacebookIcon,
  InstagramIcon,
  TwitterIcon,
  // LinkedinIcon,
  MailIcon,
} from "vue-feather-icons";
import axios from "axios";
import { API_URL, MEDIA_URL } from "@/core/api";

export default {
  data() {
    return {
      auth: localStorage.getItem("id_token") !== null ? true : false,
      recruiter:
        localStorage.getItem("user") !== null &&
        JSON.parse(localStorage.getItem("user")).role == "Direct Employer"
          ? true
          : false,
      jobSeeker:
        localStorage.getItem("user") !== null &&
        JSON.parse(localStorage.getItem("user")).role == "Job Seeker"
          ? true
          : false,
      isCondensed: false,
      cities: [],
      apiUrl: API_URL,
      search_text: "",
      selected_cities: [],
    };
  },
  mounted() {
    axios.get(`${this.apiUrl}/get-top-job-cities`).then((response) => {
      this.cities = response.data;
    });
  },
  methods: {
    search(item) {
      this.$router.replace({
        path: "/jobs/Pakistan/" + item.city_name,
        params: {
          search_text: this.search_text,
          selected_cities: [{ id: 1, value: item.city_name }],
        },
      });
    },
  },
  components: {
    FacebookIcon,
    InstagramIcon,
    TwitterIcon,
    // LinkedinIcon,
    MailIcon,
  },
};
</script>

<template>
  <div>
    <!-- Footer Start -->
    <footer class="footer mt-15">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <h4 class="text-light footer-head">Jobs by city</h4>
            <div style="display: flex">
              <!-- <ul class="list-unstyled footer-list mt-4">
                <li v-for="(item, index) in cities" :key="item.id">
                  <span
                    @click="search(item)"
                    style="cursor: pointer"
                    class="text-foot"
                    v-if="index < 5"
                    ><i class="mdi mdi-chevron-right mr-1"></i>
                    {{ item.city_name }}</span>
                </li>
              </ul>

              <ul
                class="list-unstyled footer-list mt-4"
                style="margin-right: 20%"
              >
                <li v-for="(item, index) in cities" :key="item.id">
                  <span
                    @click="search(item)"
                    style="cursor: pointer"
                    class="text-foot"
                    v-if="index > 4"
                    ><i class="mdi mdi-chevron-right mr-1"></i>
                    {{ item.city_name }}</span
                  >
                </li>
              </ul> -->
              <ul class="list-unstyled footer-list mt-4 small-text-mb">
                <li>
                  <span>
                    <i class="mdi mdi-chevron-right mr-1"></i>
                    <a href="/jobs/Pakistan/Lahore"> Lahore</a>
                  </span>
                </li>
                <li>
                  <span>
                    <i class="mdi mdi-chevron-right mr-1"></i>
                    <a href="/jobs/Pakistan/Faisalabad"> Faisalabad</a>
                  </span>
                </li>
                <li>
                  <span>
                    <i class="mdi mdi-chevron-right mr-1"></i>
                    <a href="/jobs/Pakistan/Rawalpindi"> Rawalpindi</a>
                  </span>
                </li>
                <li>
                  <span>
                    <i class="mdi mdi-chevron-right mr-1"></i>
                    <a href="/jobs/Pakistan/Karachi"> Karachi</a>
                  </span>
                </li>
                <li>
                  <span>
                    <i class="mdi mdi-chevron-right mr-1"></i>
                    <a href="/jobs/Pakistan/Hyderabad"> Hyderabad</a>
                  </span>
                </li>
              </ul>
              <ul class="list-unstyled footer-list mt-4 small-text-mb ml-20">
                <li>
                  <span>
                    <i class="mdi mdi-chevron-right mr-1"></i>
                    <a href="/jobs/Pakistan/Peshawar"> Peshawar</a>
                  </span>
                </li>
                <li>
                  <span>
                    <i class="mdi mdi-chevron-right mr-1"></i>
                    <a href="/jobs/Pakistan/Quetta"> Quetta</a>
                  </span>
                </li>
                <li>
                  <span>
                    <i class="mdi mdi-chevron-right mr-1"></i>
                    <a href="/jobs/Pakistan/Sialkot"> Sialkot</a>
                  </span>
                </li>
                <li>
                  <span>
                    <i class="mdi mdi-chevron-right mr-1"></i>
                    <a href="/jobs/Pakistan/Sheikhupurah"> Sheikhupurah</a>
                  </span>
                </li>
                <li>
                  <span>
                    <i class="mdi mdi-chevron-right mr-1"></i>
                    <a href="/jobs/Pakistan/Sargodha"> Sargodha</a>
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <!--end col-->

          <div
            class="
              col-lg-2 col-md-4 col-6
              mt-6 mt-sm-0
              pt-2 pt-sm-0
              small-text-mb
            "
            style="padding-right: 2px"
          >
            <h4 class="text-light footer-head">Employers</h4>
            <ul class="list-unstyled footer-list mt-4">
              <li>
                <a href="/login" class="text-foot"
                  ><i class="mdi mdi-chevron-right mr-1"></i> Login</a
                >
              </li>
              <li>
                <a href="/recruiter-registeration" class="text-foot"
                  ><i class="mdi mdi-chevron-right mr-1"></i> Create account</a
                >
              </li>
              <li>
                <router-link to="/recruiter/jobs/create" class="text-foot"
                  ><i class="mdi mdi-chevron-right mr-1"></i> Post a
                  job</router-link
                >
              </li>
              <li v-if="auth && recruiter">
                <router-link to="/dashboard" class="text-foot"
                  ><i class="mdi mdi-chevron-right mr-1"></i> Search
                  candidates</router-link
                >
              </li>
              <li>
                <router-link to="/contact-us" class="text-foot"
                  ><i class="mdi mdi-chevron-right mr-1"></i>
                  Contact</router-link
                >
              </li>
              <li>
                <router-link
                  to="/about-job4upk-jobs-in-Pakistan"
                  class="text-foot"
                  ><i class="mdi mdi-chevron-right mr-1"></i> About</router-link
                >
              </li>
            </ul>
          </div>
          <!--end col-->

          <div
            class="
              col-lg-3 col-md-4 col-6
              mt-6 mt-sm-0
              pt-2 pt-sm-0
              small-text-mb
            "
          >
            <h4 class="text-light footer-head">Job seekers</h4>
            <ul class="list-unstyled footer-list mt-4">
              <li>
                <a href="/login" class="text-foot"
                  ><i class="mdi mdi-chevron-right mr-1"></i> Login</a
                >
              </li>
              <li>
                <a href="/job-seeker-register" class="text-foot"
                  ><i class="mdi mdi-chevron-right mr-1"></i> Create account</a
                >
              </li>
              <li>
                <router-link to="/contact-us" class="text-foot"
                  ><i class="mdi mdi-chevron-right mr-1"></i>
                  Contact</router-link
                >
              </li>
              <li>
                <router-link to="/recruiter-directory" class="text-foot"
                  ><i class="mdi mdi-chevron-right mr-1"></i> Recruiter
                  directory</router-link
                >
              </li>
            </ul>
          </div>
          <!--end col-->

          <div class="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <h4 class="text-light footer-head">Newsletter</h4>
            <p class="mt-4 small-text-mb">
              Sign up for latest news and industry updates
            </p>
            <form>
              <div class="row">
                <div class="col-lg-12">
                  <div class="foot-subscribe form-group position-relative">
                    <!-- <label
                      >Write your email
                      <span class="text-danger">*</span></label
                    > -->
                    <mail-icon class="fea icon-sm icons"></mail-icon>
                    <input
                      type="email"
                      name="email"
                      id="emailsubscribe"
                      class="form-control pl-5 rounded"
                      placeholder="Your email : "
                      required
                    />
                  </div>
                </div>
                <div class="col-lg-12">
                  <input
                    type="submit"
                    id="submitsubscribe"
                    name="send"
                    class="btn btn-soft-primary btn-block"
                    value="Subscribe"
                  />
                </div>
              </div>
            </form>
            <ul class="list-unstyled social-icon social mb-0 mt-2 text-start">
              <li class="list-inline-item">
                <a href="https://www.facebook.com/www.job4u.pk" class="rounded">
                  <facebook-icon class="fea icon-sm fea-social"></facebook-icon>
                </a>
              </li>
              <li class="list-inline-item ml-1">
                <a href="https://www.instagram.com/wwwjob4upk/" class="rounded">
                  <instagram-icon
                    class="fea icon-sm fea-social"
                  ></instagram-icon>
                </a>
              </li>
              <li class="list-inline-item ml-1">
                <a href="https://twitter.com/wwwjob4upk" class="rounded">
                  <twitter-icon class="fea icon-sm fea-social"></twitter-icon>
                </a>
              </li>
              <li class="list-inline-item ml-1">
                <a href="#" class="rounded">
                  <img
                    src="/images/Whatsapp.svg"
                    width="15"
                    height="15"
                    alt="..."
                    class=""
                  />
                </a>
              </li>
              <!-- <li class="list-inline-item ml-1">
                <a href="javascript:void(0)" class="rounded">
                  <linkedin-icon class="fea icon-sm fea-social"></linkedin-icon>
                </a>
              </li> -->
            </ul>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </footer>
    <!--end footer-->
    <footer class="footer footer-bar">
      <div class="container text-center">
        <div class="row align-items-center">
          <div class="col-sm-6">
            <div class="text-sm-left">
              <p class="mb-0">© job4u.pk 2022.</p>
            </div>
          </div>
          <div class="col-sm-6 col-12 row m-0">
            <div class="text-sm-right col-lg-8 col-5 p-0">
              <a href="/privacy-policy" class="ml-2 text-primary"
                >Privacy Policy</a
              >
            </div>
            <div class="text-sm-right col-lg-4 col-7 p-0">
              <a href="/terms-and-conditions" class="ml-2 text-primary"
                >Terms and Conditions</a
              >
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </footer>
    <!--end footer-->
  </div>
</template>

<style scoped>
span,
a,
p,
h4,
label {
  color: white !important;
}
</style>
